import {doDelete, doGet, doPost, doPut} from "@/services/api.service";
import {User} from "@/types/user";
import {Building} from "@/types/building";
import {Token} from "@/types/token";
import {RefreshPayload} from "@/types/refresh-payload";
import {LoginRequest} from "@/types/common";
import {Group} from "@/types/group";
import {Notification} from "@/types/notification";
import {Account} from "@/types/account";
import {AlertConsumption} from "@/types/alert-consumption";
import {AlertConsumptionHistory} from "@/types/alert-consumption-history";
import {AddAccountDto} from "@/types/add-account-dto";
import {EditAccountDto} from "@/types/edit-account-dto";
import {Gateway} from "@/types/gateway";
import {AddGroupDto} from "@/types/add-group-dto";
import {AddBuildingToGroupDto} from "@/types/add-building-to-group-dto";
import {UpdateGroupDto} from "@/types/update-group-dto";
import {AddUserDto} from "@/types/add-user-dto";
import {UpdateUserDto} from "@/types/update-user-dto";
import {AddBuildingDto} from "@/types/add-building-dto";
import {AddGatewayDto} from "@/types/add-gateway-dto";
import {UpdateGatewayDto} from "@/types/update-gateway-dto";
import {AddDeviceDto} from "@/types/add-device-dto";
import {Hardware} from "@/types/hardware";
import {UpdateDeviceDto} from "@/types/update-device-dto";
import {DeviceGroup} from "@/types/device-group";
import {ConsultantAccount} from "@/types/consultant-account";
import {UpdateBuildingDto} from "@/types/update-building-dto";
import {GraphData} from "@/types/graph-data";
import {AddEditAlertDto} from "@/types/add-edit-alert-dto";
import {AddManualControlDto} from "@/types/add-manual-control-dto";
import {Control} from "@/types/control";
import {AddDeviceGroupDto} from "@/types/add-device-group-dto";
import {UpdateDeviceGroupDto} from "@/types/update-device-group-dto";
import {AddBuildingToUserDto} from "@/types/add-building-to-user-dto";
import {AddDeviceToGroupDto} from "@/types/add-device-to-group-dto";
import {DuplicateSector} from "@/types/duplicate-sector";
import {Point} from "@/types/point";
import {Map} from "@/types/map";
import {AddMapDto} from "@/types/add-map-dto";
import {EditMapDto} from "@/types/edit-map-dto";
import {UpsertPointDto} from "@/types/upsert-point-dto";

export const ApiEndpoints = {
    login: doPost<LoginRequest, Token>('api/auth/login'),
    getUser: doGet<{}, User>('api/auth/user'),
    logout: doPost<{}, {}>('api/auth/logout'),
    refreshToken: doPost<RefreshPayload, Token>('api/auth/refresh'),
    getUserBuildings: doGet<{}, Building[]>('api/buildings/getBuildingsOfCurrentUser'),
    searchBuildings: (s: string) => doGet<{}, Building[]>(`api/buildings/search/${s}`),
    getUserGroup: doGet<{}, Group[]>('api/groups'),
    getAlertsConsumption: doGet<{}, AlertConsumption[]>('api/alerts_consumption'),
    getAlertsConsumptionHistory: doGet<{}, AlertConsumptionHistory[]>('api/alerts_consumption/history'),
    notifications: doGet<{}, Notification[]>('api/notifications'),
    deleteNotification: (id: number) => doDelete<{}, {}>(`api/notifications/${id}`),
    deleteAllNotifications: doDelete<{}, {}>('api/notifications/all'),
    buildingInfo: (s: string) => doGet<{}, Building>(`api/buildings/show/${s}`),
    accounts: doGet<{}, Account[]>('api/accounts'),
    getAccountById: (accountId: number) => doGet<{}, Account>(`/api/accounts/show/${accountId}`),
    searchAccounts: (s: string) => doGet<{}, Account[]>(`api/accounts/search/${s}`),
    addAccount: doPost<AddAccountDto, Account>('api/accounts'),
    resetPwdMail: doPost('api/auth/resetPasswordMail'),
    addPoint: doPost<UpsertPointDto, Point>('api/points/'),
    deletePoint: (id: number) => doDelete<{}, {}>(`api/points/${id}`),
    updatePoint: (pointId: number) => doPut<UpsertPointDto, Point>(`/api/points/${pointId}`),
    getMapsByBuildingId: (buildingId:number) => doGet<{}, Map[]>(`api/maps/${buildingId}`),
    getMap: (id:number) => doGet<{}, Map>(`api/maps/show/${id}`),
    getPointsByMapId: (mapId:number) => doGet<{}, Point[]>(`api/points/${mapId}`),
    getPointsByBuildingId: (buildingId:number) => doGet<{}, Point[]>(`api/points/building/${buildingId}`),
    getPointById: (pointId:number) => doGet<{}, Point>(`api/points/show/${pointId}`),
    editAccount: (id: number) => doPut<EditAccountDto, Account>(`api/accounts/${id}`),
    deleteAccount: (id: number) => doDelete<{}, {}>(`api/accounts/${id}`),
    usersOfAccount: (id: number) => doGet<{}, User[]>(`api/users/${id}`),
    buildingsOfUser: (accountId: number, userId: number) => doGet<{}, Building[]>(`api/buildings/getBuildingsByUserId/${accountId}/${userId}`),
    gatewaysOfBuilding: (id: number) => doGet<{}, Gateway[]>(`api/gateways/${id}`),
    removeBuildingFromGroup: (buildingId: number) => doDelete<{}, {}>(`/api/buildings/${buildingId}/group`),
    addBuildingFromGroup: (buildingId: number) => doPut<{ groupId: number }, {}>(`/api/buildings/${buildingId}/group`),
    createAlert: doPost<AddEditAlertDto, AlertConsumption>('/api/alerts_consumption'),
    updateAlert: (alertId: number) => doPost<AddEditAlertDto, AlertConsumption>(`/api/alerts_consumption/${alertId}`),
    deleteAlert: (alertId: number) => doDelete<{}, AlertConsumption[]>(`/api/alerts_consumption/${alertId}`),
    deletePeriod: (periodId: number) => doDelete<{}, {}>(`/api/controls/${periodId}`),
    deleteGroup: (groupId: number) => doDelete<{}, {}>(`/api/groups/${groupId}`),
    createGroup: doPost<AddGroupDto, Group>('/api/groups'),
    addBuildingToGroup: (buildingId: number) => doPut<AddBuildingToGroupDto, Building>(`/api/buildings/${buildingId}/group`),
    updateGroup: (groupId: number) => doPut<UpdateGroupDto, Group>(`/api/groups/${groupId}`),
    addUser: doPost<AddUserDto, User>('/api/users'),
    updateUser: (userId: number) => doPut<UpdateUserDto, User>(`/api/users/${userId}`),
    updatePwd: doPut(`/api/auth/update/password`),
    updatePwdLogged: doPut(`/api/auth/update/password/logged`),
    deleteUser: (userId: number) => doDelete<{}, {}>(`/api/users/${userId}`),
    addBuilding: doPost<AddBuildingDto, Building>('/api/buildings'),
    updateBuilding: (buildingId: number) => doPut<UpdateBuildingDto, Building>(`/api/buildings/${buildingId}`),
    deleteBuilding: (userId: number, buildingId: number) => doDelete<{}, {}>(`/api/buildings/deleteBuilding/${buildingId}/${userId}`),
    addGateway: doPost<AddGatewayDto, Gateway>('/api/gateways'),
    updateGateway: (gatewayId: number) => doPut<UpdateGatewayDto, Gateway>(`/api/gateways/${gatewayId}`),
    deleteGateway: (gatewayId: number) => doDelete<{}, {}>(`/api/gateways/${gatewayId}`),
    addDevice: doPost<AddDeviceDto, Hardware>('/api/devices'),
    updateDevice: (deviceId: number) => doPut<UpdateDeviceDto, Hardware>(`/api/devices/${deviceId}`),
    deleteDevice: (deviceId: number) => doDelete<{}, {}>(`/api/devices/${deviceId}`),
    devicesOfGateway: (gatewayId: number) => doGet<{}, Hardware[]>(`/api/devices/${gatewayId}`),
    getDevice: (hardware_id: number) => doGet<{}, Hardware>(`/api/devices/show/${hardware_id}`),
    devicesOfBuilding: (buildingId: number) => doGet<{}, Hardware[]>(`/api/devices/getDevicesByBuildingId/${buildingId}`),
    deviceGroupsOfGateway: (gatewayId: number) => doGet<{}, DeviceGroup[]>(`/api/groupsofdevices/${gatewayId}`),
    deviceGroupOfBuilding: (buildingId: number) => doGet<{}, DeviceGroup[]>(`/api/groupsofdevices/groupsByBuilding/${buildingId}`),
    putDeviceGroupChart: (itemId: number) => doPut<{}, {}>(`/api/buildings/${itemId}/charts`),
    getGroupDetail: (groupCode: string, startDate: string, endDate: string) => doGet<{}, GraphData>(`api/back/graph/getGroupDetail/${groupCode}/${startDate}/${endDate}`),
    getConsultantGroups: doGet<{}, ConsultantAccount[]>('/api/accounts/consultantGroup'),
    getConsultants: (accountId: number) => doGet<{}, User[]>(`/api/accounts/consultant/${accountId}`),
    addManualControl: doPost<AddManualControlDto, Control>('/api/controls/manual'),
    putControlUpdated: (controlId: number) => doPut<AddManualControlDto, Control>(`/api/controls/${controlId}`),
    addDeviceGroup: doPost<AddDeviceGroupDto, DeviceGroup>('/api/groupsofdevices'),
    deleteDeviceGroup: (groupId: number) => doDelete<{}, {}>(`/api/groupsofdevices/${groupId}`),
    updateDeviceGroup: (groupId: number) => doPut<UpdateDeviceGroupDto, DeviceGroup>(`/api/groupsofdevices/${groupId}`),
    removeBuildingFromUser: (userId: number, buildingId: number) => doDelete<{}, {}>(`/api/buildings/removeBuildingsFromUser/${buildingId}/${userId}`),
    addBuildingToUser: (userId: number, buildingId: number) => doPut<AddBuildingToUserDto, {}>(`/api/buildings/addBuildingsOfUser/${buildingId}/${userId}`),
    buildingsByAccountId: (accountId: number) => doGet<{}, Building[]>(`/api/buildings/${accountId}`),
    addDeviceToGroup: (deviceId: number) => doPut<AddDeviceToGroupDto, {}>(`/api/devices/${deviceId}/groupofdevices`),
    removeDeviceFromGroup: (deviceId: number, gatewayId: number) => doDelete<{}, {}>(`/api/devices/${deviceId}/${gatewayId}/groupofdevices`),
    importDeviceCsv: doPost<FormData, {
        data: any
    }>('/api/devices/import'),
    duplicateSector: (groupId: number) => doPost<DuplicateSector, {}>(`/api/groupsofdevices/duplicate/${groupId}`),
    syncMqttGateway: (gatewayId: number) => doPut<{}, {}>(`/api/gateways/sync-mqtt/${gatewayId}`),
    mapsOfBuilding: (buildingId: number) => doGet<{}, Map[]>(`/api/maps/${buildingId}`),
    addMap: doPost<FormData, Map>('api/maps'),
    editMap: (mapId: number) => doPut<EditMapDto, {}>(`api/maps/${mapId}`),
    deleteMap: (mapId: number) => doDelete<{}, {}>(`api/maps/${mapId}`),
};
