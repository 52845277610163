import {fetchWithAuth} from "@/services/auth.service";

class RequestOptions {
    auth: boolean = true;
}

function getParametrizedUrl(baseUrl: string, params: any): string {
    const url = new URL(baseUrl);
    Object.keys(params).forEach(key => url.searchParams.append(key, params[key]));
    return url.toString();
}

function doRequestWithQueryParams<I, O>(method: string, url: string) {
    console.log(url);

    return async function (params: I, headers: HeadersInit = {}, opts: RequestOptions = new RequestOptions()): Promise<O> {
        const fetchFn = opts.auth ? fetchWithAuth : fetch;

        const response = await fetchFn(getParametrizedUrl(url, params), {
            method: method,
            headers: headers,
        });

        if (!response.ok) {
            throw new Error(response.statusText);
        }

        try {
            return await response.clone().json();
        } catch (e) {
            return await response.text() as O;
        }
    }
}

function doRequestWithBody<I, O>(method: string, url: string) {
    return async function (input: I, headers: HeadersInit = {}, opts: RequestOptions = new RequestOptions()): Promise<O> {
        const fetchFn = opts.auth ? fetchWithAuth : fetch;

        const isFormData = input instanceof FormData;

        const response = await fetchFn(url, {
            method: method,
            headers: headers,
            body: isFormData ? input as FormData : JSON.stringify(input),
        });

        if (!response.ok) {
            throw new Error(response.statusText);
        }

        try {
            return await response.clone().json();
        } catch (e) {
            return await response.text() as O;
        }
    }
}

function getFullUrl(path: string): string {
    return new URL(path, process.env.NEXT_PUBLIC_API_URL).toString();
}

export function doGet<I, O>(path: string) {
    return doRequestWithQueryParams<I, O>('GET', getFullUrl(path));
}

export function doPost<I, O>(path: string) {
    return doRequestWithBody<I, O>('POST', getFullUrl(path));
}

export function doPut<I, O>(path: string) {
    return doRequestWithBody<I, O>('PUT', getFullUrl(path));
}

export function doDelete<I, O>(path: string) {
    return doRequestWithBody<I, O>('DELETE', getFullUrl(path));
}
